import React, { useEffect, useState } from 'react';
import { H1, LabelMedium } from 'baseui/typography';
import { Input } from 'baseui/input';
import { Search } from 'baseui/icon';
import { styled } from 'baseui';
import { Button } from 'baseui/button';
import { Table } from 'baseui/table-semantic';
import { Skeleton } from 'baseui/skeleton';
import { StyledLink } from 'baseui/link';
import { getTracks } from './util/getTracks';
import { ChinookItem } from './types/ChinookItem';
import { formatMs } from './util/formatMs';

const SearchBar = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
});

const RecentBar = styled('div', {
    marginTop: '10px',
    height: '20px',
    marginBottom: '1em',
});

const Footer = styled('div', {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
});

const LoadingData = Array(10).fill([
    <Skeleton width="500px" height="20px" />,
    <Skeleton width="300px" height="20px" />,
    <Skeleton width="300px" height="20px" />,
]);

export const Library = () => {
    const [page, setPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [query, setQuery] = useState<string>('');
    const [recentQuery, setRecentQuery] = useState<string>('');
    const [data, setData] = useState<ChinookItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (query === recentQuery || query === '') {
            setLoading(true);
            getTracks(page, query)
                .then((res) => {
                    setData(res.data);
                    setTotalPages(res.meta?.pages ?? 1);
                    setLoading(false);
                });
        }
    }, [page, query, recentQuery]);

    return (
        <>
            <H1>My Library</H1>
            <SearchBar>
                <Input
                    startEnhancer={() => <Search size={20} />}
                    value={query}
                    placeholder="Type a search term here..."
                    onChange={(e) => {
                        setQuery(e.currentTarget.value);
                    }}
                    clearable
                />
                <Button
                    onClick={() => {
                        setLoading(true);
                        setPage(1);
                        setRecentQuery(query);
                    }}
                >
                    Search
                </Button>
            </SearchBar>
            <RecentBar>
                <LabelMedium>
                    {recentQuery !== '' ? (
                        <>
                            {'Recent query: '}
                            <StyledLink
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setQuery(recentQuery);
                                    setLoading(true);
                                    setPage(1);
                                    getTracks(1, query)
                                        .then((res) => {
                                            setData(res.data);
                                            setTotalPages(res.meta?.pages ?? 1);
                                            setLoading(false);
                                        });
                                }}
                            >
                                {recentQuery}
                            </StyledLink>
                        </>
                    ) : ''}
                </LabelMedium>
            </RecentBar>
            <Table
                columns={['Name', 'Composer', 'Minutes']}
                data={loading ? LoadingData : data.map((item) => [
                    item.attributes.name,
                    item.attributes.composer || 'N/A',
                    formatMs(item.attributes.milliseconds || 0),
                ])}
            />
            <Footer>
                <LabelMedium>{`Page ${page} of ${totalPages}`}</LabelMedium>
                <div>
                    <Button
                        onClick={() => {
                            setPage(page - 1);
                        }}
                        disabled={page <= 1}
                    >
                        Previous page
                    </Button>
                    <Button
                        onClick={() => {
                            setPage(page + 1);
                        }}
                        disabled={page >= totalPages}
                    >
                        Next page
                    </Button>
                </div>
            </Footer>
        </>
    );
};
