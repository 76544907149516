import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { styled } from 'baseui';
import Nav from './components/Nav';
import { Library } from './Library';
import { Playlists } from './Playlists';
import { PlaylistDetails } from './PlaylistDetails';
import { Content } from './components/Content';

const Layout = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    height: '100vh',
});

const NavContainer = styled('div', ({ $theme }) => ({
    width: '200px',
    borderRightColor: $theme.colors.contentTertiary,
    borderRightStyle: 'solid',
    borderRightWidth: '1px',
}));

function App() {
    return (
        <BrowserRouter>
            <Layout>
                <NavContainer>
                    <Nav />
                </NavContainer>
                <Switch>
                    <Route exact path="/">
                        <Content>
                            <Library />
                        </Content>
                    </Route>
                    <Route exact path="/playlists">
                        <Content>
                            <Playlists />
                        </Content>
                    </Route>
                    <Route exact path="/playlists/:id" component={PlaylistDetails} />
                </Switch>
            </Layout>
        </BrowserRouter>
    );
}

export default App;
