import React, { useEffect, useState } from 'react';
import { H1, LabelMedium, ParagraphMedium } from 'baseui/typography';
import { StyledSpinnerNext } from 'baseui/spinner';
import { Link } from 'react-router-dom';
import { ChinookApiResponse } from './types/ChinookApiResponse';
import { ChinookItem } from './types/ChinookItem';

const getPlaylists = async () => {
    const res = await fetch('https://chinook-api.herokuapp.com/api/playlists');
    if (res.ok) {
        return (await res.json()) as ChinookApiResponse;
    }
    throw new Error(`Error fetching tracks from API; received status ${res.status}`);
};

export const Playlists = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [playlists, setPlaylists] = useState<ChinookItem[]>([]);
    useEffect(() => {
        getPlaylists()
            .then((res) => {
                setPlaylists(res.data);
                setLoading(false);
            });
    }, []);
    return (
        <>
            <H1>Playlists</H1>
            {loading ? (
                <StyledSpinnerNext />
            ) : (
                <>
                    <LabelMedium>
                        {`${playlists.length} playlists`}
                    </LabelMedium>
                    {playlists.map((pl) => (
                        <ParagraphMedium>
                            <Link to={`/playlists/${pl.id}`}>
                                {pl.attributes.name}
                            </Link>
                        </ParagraphMedium>
                    ))}
                </>
            )}
        </>
    );
};
