import React from 'react';
import { Navigation } from 'baseui/side-navigation';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const Nav = ({ history, location }: RouteComponentProps) => (
    <Navigation
        items={[
            {
                title: 'Library',
                itemId: '/',
            },
            {
                title: 'Playlists',
                itemId: '/playlists',
            },
        ]}
        activeItemId={location.pathname}
        onChange={({ event, item }) => {
            event.preventDefault();
            history.push(item.itemId);
        }}
    />
);

export default withRouter(Nav);
