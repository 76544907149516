import React, { useEffect, useState } from 'react';
import { RouteChildrenProps } from 'react-router-dom';
import { H1, ParagraphMedium } from 'baseui/typography';
import { StyledSpinnerNext } from 'baseui/spinner';
import { Table } from 'baseui/table-semantic';
import { Content } from './components/Content';
import { ChinookItem } from './types/ChinookItem';
import { formatMs } from './util/formatMs';

interface ChinookPlaylistApiResponse {
    included: ChinookItem[],
    data: ChinookItem,
}

const getPlaylist = async (id: string): Promise<ChinookPlaylistApiResponse | 404> => {
    const res = await fetch(`https://chinook-api.herokuapp.com/api/playlists/${id}`);
    if (res.ok) {
        return (await res.json()) as ChinookPlaylistApiResponse;
    }
    if (res.status === 404) {
        return 404;
    }
    throw new Error(`Error fetching tracks from API; received status ${res.status}`);
};

export const PlaylistDetails = ({ match }: RouteChildrenProps<{ id: string }>) => {
    const [data, setData] = useState<ChinookPlaylistApiResponse | 404 | null>(null);
    const id = match?.params.id ?? '1';
    useEffect(() => {
        getPlaylist(id)
            .then(setData);
    }, [id]);
    const fallback = (data === 404) ? (
        <H1>Page not found</H1>
    ) : <StyledSpinnerNext />;
    return (
        <Content>
            {(data !== null && data !== 404) ? (
                <>
                    <H1>{data.data.attributes.name ?? ''}</H1>
                    <ParagraphMedium>
                        {`This playlist has ${data.included?.length ?? 'no'} tracks.`}
                    </ParagraphMedium>
                    {data.included ? (
                        <Table
                            columns={['Name', 'Composer', 'Minutes']}
                            data={data.included.map((item) => [
                                item.attributes.name,
                                item.attributes.composer || 'N/A',
                                formatMs(item.attributes.milliseconds || 0),
                            ])}
                        />
                    ) : <></>}
                </>
            ) : fallback}
        </Content>
    );
};
